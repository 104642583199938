import Vue from 'vue'
import BootstrapVue from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
Vue.use(BootstrapVue)
import App from './App.vue'
import router from './router'
import store from './store'
import VueAwesomeSwiper  from 'vue-awesome-swiper'
import 'swiper/swiper-bundle.css'
Vue.use(VueAwesomeSwiper);
Vue.config.productionTip = false
import BaiduMap from 'vue-baidu-map'
Vue.use(BaiduMap, {
  ak: 'OfldXkI591GW281wpaUBSHES&services=&t=20190102133327'
})

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')

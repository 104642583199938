<template>
  <div id="app">
    <tab-nav/>
    <router-view class="router"/>
    <main-footer/>
    <side-bar/>
  </div>
</template>

<style lang="less">
  @import "assets/css/base.css";
  @media (min-width: 992px) {
    .aboutRight{
      padding-left: 5%;
    }
  }
</style>
<style scoped>
</style>
<script>

  import TabNav from "./components/content/TabNav";
  import MainFooter from "./components/content/MainFooter";
  import SideBar from "./components/content/SideBar";
  export default {
    components: {SideBar, MainFooter, TabNav},
    created() {
      this.$store.dispatch('changeListAsync');
      this.$store.dispatch('changeListAsync2');
    }
  }
</script>
import Vue from 'vue'
import VueRouter from 'vue-router'
Vue.use(VueRouter)
const routes = [
  {
    path: '',
    redirect: '/home'
  },
  {
    path: '/home',
    name: 'Home',
    meta: {
      title: '天润化工_天润供应链-泉州天润供应链管理有限公司',
    },
    component: () => import('../views/home/Home')
  },
  {
    path: '/about',
    name: 'About',
    meta: {
      title: '泉州天润供应链管理有限公司',
    },
    component: () => import('../views/about/About')
  },
  {
    path: '/service',
    name: 'ServicePlan',
    meta: {
      title: '泉州天润供应链管理有限公司',
    },
    component: () => import('../views/serviceplan/ServicePlan')
  },
  {
    path: '/news',
    name: 'News',
    meta: {
      title: '泉州天润供应链管理有限公司',
    },
    component: () => import('../views/news/News')
  },
  {
    path: '/newsdetail/:tid',
    name: 'NewsDetail',
    meta: {
      title: '泉州天润供应链管理有限公司',
    },
    component: () => import('../views/newsdetail/NewsDetail')
  },
  {
    path: '/careerdeve',
    name: 'CareerDeve',
    meta: {
      title: '泉州天润供应链管理有限公司',
    },
    component: () => import('../views/careerdeve/CareerDeve')
  },
  {
    path: '/contactus',
    name: 'ContactUs',
    meta: {
      title: '泉州天润供应链管理有限公司',
    },
    component: () => import('../views/contactus/ContactUs')
  },
  {
    path: '/industrial',
    name: 'Industrial',
    meta: {
      title: '泉州天润供应链管理有限公司',
    },
    component: () => import('../views/industrial/Industrial')
  },
  {
    path: '/industryApp',
    name: 'IndustryApp',
    meta: {
      title: '泉州天润供应链管理有限公司',
    },
    component: () => import('../views/industryapp/IndustryApp')
  },
  {
    path: '/productdetail/:tid',
    name: 'ProductDetail',
    meta: {
      title: '泉州天润供应链管理有限公司',
    },
    component: () => import('../views/productdetail/ProductDetail')
  },
  {
    path: '/search',
    name: 'Search',
    meta: {
      title: '泉州天润供应链管理有限公司',
    },
    component: () => import('../views/search/Search')
  },
  {
    path: '/searchcontent',
    name: 'SearchContent',
    meta: {
      title: '泉州天润供应链管理有限公司',
    },
    component: () => import('../views/searchcontent/SearchContent')
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})
router.afterEach(((to, from) => {
  window.scrollTo({
    top: 0,
    behavior: 'auto'
  })
  document.title = to.matched[0].meta.title;
}))
export default router

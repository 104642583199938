import Vue from 'vue'
import Vuex from 'vuex'
import {request} from 'network/request';
Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    //是否展示产品详情页
    showProductDtl:false,
    navInfo:[
      {
        text:'关于我们',
        textE:'About us',
        link:'/about',
        // childList:['企业概况','企业文化','荣誉资质'],
        childList:[{
          title:'企业概况',
          titleE:'Company Profile',
        },{
          title:'企业文化',
          titleE:'Company culture',
        },{
          title:'荣誉资质',
          titleE:'Honor',
        },],
      },
      {
        text:'产业布局',
        textE:'Industrial Distribution',
        link:'/industrial',
        childList:null,
      },
      {
        text:'行业应用',
        textE:'Industry Application',
        ico:'',
        link:'/industryapp',
        childList:null,
      },
      {
        text:'服务方案',
        textE:'Service plan',
        link:'/service',
        childList:[{title:'解决方案',titleE:'Solution'}],
      },
      {
        text:'新闻资讯',
        textE:'News',
        link:'/news',
        // childList:['企业新闻'],
        childList:[{title:'企业新闻',titleE:'Corporate News'}],
      },
      {
        text:'职业发展',
        textE:'Career Development',
        link:'/careerdeve',
        // childList:['人才培养','热招岗位'],
        childList:[{title:'人才培养',titleE:'Talent Development'},{title:'热招岗位',titleE:'Hot jobs'}],
      },
      {
        text:'联系我们',
        textE:'contact us',
        link:'/contactus',
        // childList:['联系方式','在线留言'],
        childList:[{title:'联系方式',titleE:'Contact information'},{title:'在线留言',titleE:'Online message'}],
      },
    ],
  },
  mutations: {
    sortList(state,payload) {
      state.navInfo[1].childList=payload;
      // console.log(state.navInfo[1].childList)
    },
    sortList2(state,payload) {
      state.navInfo[2].childList=payload;
      // console.log(state.navInfo[2].childList);
    }
  },
  actions: {
    changeListAsync (context) {
      request({
        params:{
          route:'product/category/getCategory',
        },

      }).then(res=>{
        context.commit('sortList',res.data.left_category_1)
      })
    },
    changeListAsync2 (context) {
      request({
        params:{
          route:'product/category/getTwoCategory',
        },
      }).then(res=>{
        context.commit('sortList2',res.data.category_2)
      })
    }
  },
  modules: {
/*
*  request({
    url:'common/home/homesql',
  })
* */

  }
})

<template>
  <div class="footer">
    <div class="footerTop">
      <router-link :to="{
        path:'/contactus',
        query:{index:0}
      }" tag="p" class="footerTopBut">
        <img class="footerTopIco" src="~assets/images/icon/footerContactIco.png"/>
        <p class="footerTopText">联系我们</p>
      </router-link>
    </div>
    <div class="container-fluid">
      <div class="row footerBottom">
        <div class="col-lg-8 d-lg-flex d-none footerLeft">
          <div class="footerLeftItem" v-for="item in list">
            <p class="footerLeftItemTitle">{{item.text}}</p>
            <p class="footerLeftItemChild" v-for="(childText,index) in item.childList"
               @click="itemListItem(item.link,index,childText.category_id)">{{childText.title || childText.name}}</p>
          </div>
        </div>
        <div class="col-lg-4 align-items-lg-end align-items-center col-12 footerRight">
          <img class="footerRightQRCode" v-if="info.wechatImg" :src="info.wechatImg"/>
          <p class="footerRightName">天润（国际）官方微信号</p>
          <div class="footerRightLinkBox d-lg-flex d-flex justify-content-around align-items-lg-end flex-lg-column align-items-center">
            <div class="footerRightIcoCoat">
              <a href="#"><img class="footerRightIco" src="~assets/images/icon/weChatIco.png"/></a>
              <a :href="'http://wpa.qq.com/msgrd?v=3&uin='+info.QQ+'&site=qq&menu=yes'"><img class="footerRightIco" src="~assets/images/icon/QQIco.png"/></a>
            </div>
            <div class="friendshipLinks" @mouseenter="setLinksChild(1)" @mouseleave="setLinksChild(0)">
              <div class="linksBut">
                <p class="linksButText">友情链接</p>
                <p class="linksButArrow">∧</p>
              </div>
              <div class="linksChild" :style="LinksChildType" v-if="friendshipLinks.length>0">
                <a v-for="item in friendshipLinks" class="linksChildItem" :href="item.link"  target="_blank">{{item.link_name}}</a>
              </div>
            </div>
          </div>
          <p class="caseNumber">{{info.pagefoot}}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import {getSidebar} from 'network/sidebar';
  export default {
    name: "MainFooter",
    computed: {
      list() {
        return this.$store.state.navInfo;
      }
    },
    data(){
      return {
        LinksChildType:'',
        friendshipLinks:[],
        info:{
          QQ:'',
          wechatImg:null,
          pagefoot:''
        }
      }
    },
    methods: {
      itemListItem(link, index,category_id) {
        if (this.$route.query.index != index || this.$route.path!=link) {
          this.$router.push({
            query: {
              index,category_id
            },
            path: link
          })
        }
      },
      setLinksChild(type){
        let number=this.friendshipLinks.length;
        if (type==1){
          this.LinksChildType='height:'+(number*40)+'px;!important;transition: height .3s;';
        }else {
          this.LinksChildType='height:0px!important;transition: height .3s;';
        }
      }
    },
    created() {
      getSidebar().then(res=>{
        this.info.QQ=res.data.QQ;
        this.info.wechatImg=res.data.Wechat;
        this.info.pagefoot=res.data.pagefoot;
        this.friendshipLinks=res.data.link;
        // console.log(res.data);
      })
    }
  }
</script>

<style scoped>
  .footerRightLinkBox{
    width: 100%;
  }
  .footerRight {
    display: flex;
    flex-direction: column;
  }
  .footerRightQRCode{
    width: 4rem;
    height: auto;
    margin-bottom: .4rem;
  }
  .footerRightName{
    font-size: .4rem;
    color: #e5e5e5;
    margin-bottom: .75rem;
  }
  .footerRightIcoCoat{
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-bottom: .75rem;
  }
  .footerRightIco{
    margin-left: .7rem;
    width: 1.15rem;
    height: auto;
  }

  .friendshipLinks{
    display: inline-flex;
    position: relative;
    cursor: pointer;
  }
  .linksBut{
    display: flex;
    align-items: center;
    padding: 0 .35rem;
    border: 1px solid #fff;
    font-size: .35rem;
    color: #fff;
    height: 40px;
  }
  .linksButText{
    margin-right: 2rem;
  }
  .linksChild{
    overflow: hidden;
    position: absolute;
    width: 100%;
    bottom: 40px;
    height: 0;
    transition: all .3s linear;
  }
  .linksChildItem{
    background-color: #3d3d3d;
    font-size: .3rem;
    color: #fff;
    padding:0 .35rem;
    border-bottom: 1px solid #4f4f4f;
    display: flex;
    align-items: center;
    height: 40px;
  }
  .caseNumber{
    margin-top: .7rem;
    font-size: .35rem;
    color: #e5e5e5;
    text-align: right;
  }


  .footer {
    width: 100%;
  }

  .footerTop {
    padding: 1.3rem var(--marginX) 1.1rem var(--marginX);
    text-align: right;
    background-image: url("~assets/images/img/footerBGI.jpg");
    background-size: cover;
  }

  .footerTopBut {
    background-color: #fff;
    display: inline-flex;
    align-items: center;
    padding: .25rem 1.2rem;
    border-radius: 20px;
    cursor: pointer;
    position: relative;
    bottom: 0;
    transition: all .1s linear;box-shadow:none;
  }
  .footerTopBut:hover{
    bottom: 3px;
    transition: bottom .1s;
  }
  .footerTopIco {
    width: auto;
    height: .7rem;
    margin-right: .45rem;
  }

  .footerTopText {
    font-size: .4rem;
    color: var(--red);
  }

  .footerBottom {
    padding: 1.6rem var(--marginX);
    background-color: #4f4f4f;
  }

  .footerLeft {
    display: flex;
    align-items: flex-start;
  }

  .footerLeftItem {
    margin-right: .6rem;
  }

  .footerLeftItemTitle {
    font-size: .35rem;
    color: #fff;
    margin-bottom: .55rem;
  }

  .footerLeftItemChild {
    font-size: .3rem;
    color: #fff;
    margin-bottom: .4rem;
    cursor: pointer;
  }

  .footerLeftItemChild:hover {
    text-shadow: 1px 1px 1px #2190E4;
  }
</style>
<template>
  <div class="tabNav">
    <img @click="logo" class="logo" src="~assets/images/icon/logo.jpg" alt="#"/>
    <div class="tabNavRight">
      <div class="tabNavRightTop">
        <router-link to="/search" tag="p" class="navRightSearch">
          <p class="navRightSearchText">产品查询</p>
          <img class="navRightSearchIcon" src="~assets/images/icon/graySearch.png" alt="#"/>
        </router-link>
        <img class="wapNavIcon d-block d-lg-none" @click="wapSwitch" src="~assets/images/icon/navIco.png"/>
      </div>
      <div class="navItemCoat d-lg-flex d-none">
        <div class="navItem" v-for="item in list">
          <router-link :to="{path:item.link,
          query:
          {
            index:0,
            category_id:item.childList && item.childList[0].path?item.childList[0].path :undefined
          }}"
                       tag="p" class="navItemText" :class="{activeNavItemText:$route.path==item.link}">{{item.text}}
          </router-link>
<!--          <p class="navItemText" @click="navItemText(item,item.link)"  :class="{activeNavItemText:$route.path==item.link}">{{item.text}}</p>-->
          <div class="navItemList">
            <div class="navItemListItem" v-for="(childItem,index) in item.childList"
                 @click="navItemListItem(item.link,index,childItem.path)">
              <p class="triangle">▲</p>
              <p class="text">{{childItem.title || childItem.name}}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="wapNavCoat" :class="{wapShows:wapShow}">
      <div class="wapNav">
        <div class="wapNavTop">
          <p class="wapOff" @click="wapSwitch">✖</p>
        </div>
        <div class="wapNavList">
          <div class="wapNavItem" v-for="(item,index) in list">
            <div class="wapNavItemTitle" :class="{acitveWapNavItemTitle:index==wapActiveIndex}"
                 @click="setWapActiveIndex(index)">
              <p>{{item.text}}</p>
              <p>{{index==wapActiveIndex?'－':'＋'}}</p>
            </div>
            <div class="wapNavChild" v-show="index==wapActiveIndex">
              <p class="wapNavChildItem" v-for="(childItem,childIndex) in item.childList"
                 @click="navItemListItem(item.link,childIndex,childItem.path)">{{childItem.title || childItem.name}}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: "TabNav",
    data() {
      return {
        wapShow: false,
        wapActiveIndex: -1,
      }
    },
    computed: {
      list() {
        return this.$store.state.navInfo;
      }
    },
    methods: {
      navItemListItem(link, index,category_id) {
        if (this.$route.query.index != index || this.$route.path!=link) {
          this.$router.push({
            query: {
              index,category_id
            },
            path: link
          })
        }
        this.wapShow = false;
      },
      logo() {
        if (this.$route.path!='/home'){
          this.$router.push('/home');
        }
      },
      wapSwitch() {
        this.wapShow = !this.wapShow;
      },
      setWapActiveIndex(index) {
        if (index == this.wapActiveIndex) {
          this.wapActiveIndex = -1;
        } else {
          this.wapActiveIndex = index;
        }
      }
    }
  }
</script>

<style scoped>
  .wapNavCoat {
    position: fixed;
    height: 100vh;
    width: 100%;
    top: 0;
    z-index: 19;
    right: -100%;
    background-color: rgba(0, 0, 0, .3);
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    transition: all .3s linear;
  }

  .wapShows {
    animation: aWapShow .3s;
    animation-fill-mode: forwards;
  }

  @keyframes aWapShow {
    0% {
      right: -100%;
    }
    100% {
      right: 0;
    }
  }

  .wapNav {
    width: 80%;
    height: 100%;
    padding: 0.3rem 0;
    background-color: #fff;
  }

  .wapNavTop {
    display: flex;
    justify-content: flex-end;
    padding: 0 var(--marginX);
  }

  .wapOff {
    cursor: pointer;
  }

  .wapNavItem {
    border-bottom: 1px solid #d9d9d9;
    cursor: pointer;
  }

  .wapNavItemTitle {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: .4rem var(--marginX);
    font-size: .7rem;
    color: #606060;
  }

  .acitveWapNavItemTitle {
    color: #fff;
    background-color: var(--red);
  }

  .wapNavChild {

  }

  .wapNavChildItem {
    padding: .4rem var(--marginX);
    font-size: .4rem;
    color: #9d9d9d;
  }


  .tabNav {
    height: 2.5rem;
    display: flex;
    align-items: center;
    background-color: #fff;
    padding: 0 var(--marginX);
    justify-content: space-between;
    position: relative;
    background-color: #fff;
    z-index: 10;
  }

  .logo {
    height: 2.2rem;
    width: auto;
    cursor: pointer;
  }

  .tabNavRightTop {
    margin-bottom: .2rem;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  .wapNavIcon {
    margin-left: .6rem;
    height: 1.3rem;
    cursor: pointer;
  }

  .navRightSearch {
    display: inline-flex;
    align-items: center;
    padding: .15rem .45rem;
    background-color: #dedede;
    border-radius: 30px;
    cursor: pointer;
  }

  .navRightSearchText {
    font-size: .35rem;
    color: #595959;
    margin-right: .15rem;
  }

  .navItemCoat {
    display: flex;
    align-items: center;
  }

  .navItem {
    display: flex;
    align-items: center;
    flex-direction: column;
    height: 1.3rem;
    cursor: pointer;
  }

  .navItemText {
    padding: .16rem .45rem;
    border-radius: 20px;
    color: #4c4c4c;
  }
  .navItem:hover .navItemText {
    color: #fff;
    background-color: var(--red);
  }
  .activeNavItemText{
    color: #fff;
    background-color: var(--red);
  }

  .navItem:hover .navItemList {
    display: block;
    animation: aNavItemList .3s;
    animation-fill-mode: forwards;
  }

  @keyframes aNavItemList {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  .navItemListItem:hover {
    background-color: #f7f7f7;
    color: var(--red);
  }

  .navItemListItem:hover .triangle {
    opacity: 1;
  }

  .navItemText {
    font-size: .35rem;

  }

  .navItemList {
    width: 4.7rem;
    position: absolute;
    top: 2.46rem;
    background-color: #fff;
    border-top: 2px solid var(--red);
    display: none;
  }

  .navItemListItem {
    font-size: .35rem;
    color: #666666;
    display: flex;
    align-items: center;
    padding: .2rem 0 .2rem 24%;
  }

  .navItemListItem .triangle {
    transform: rotate(90deg);
    margin-right: .2rem;
    color: var(--red);
    opacity: 0;
  }
</style>
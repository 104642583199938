<template>
  <div class="sidebar d-none d-md-block d-lg-block">
    <div class="sidebarItem">
      <p class="sidebarItemValue">咨询热线：{{info.phone}}</p>
      <div class="sidebarItemIconCoat"><img class="sidebarItemIcon" src="~assets/images/icon/sidebar1.png"/></div>
    </div>
    <div class="sidebarItem">
      <p class="sidebarItemValue">QQ：{{info.QQ}}</p>
      <div class="sidebarItemIconCoat"><img class="sidebarItemIcon" src="~assets/images/icon/sidebar2.png"/></div>
    </div>
    <div class="sidebarItem2">
      <div class="sidebarItemValue2">
        <img  v-if="info.wechatImg" :src="info.wechatImg">
        <p>天润企业微信公众号</p>
      </div>
      <div class="sidebarItemIconCoat"><img class="sidebarItemIcon" src="~assets/images/icon/sidebar3.png"/></div>
    </div>
    <div class="sidebarItem2">
      <div class="sidebarItemIconCoat" @click="goTop"><img class="sidebarItemIcon" src="~assets/images/icon/sidebar4.png"/></div>
    </div>
  </div>
</template>

<script>
  import {getSidebar} from 'network/sidebar';
  export default {
    name: "SideBar",
    data(){
      return {
        info:{
          QQ:'',
          wechatImg:null,
          phone:''
        }
      }
    },
    methods:{
      goTop(){
        window.scrollTo({
          top:0,
          behavior: "smooth"
        })
      }
    },
    created() {
      getSidebar().then(res=>{
        // console.log(res.data);
        this.info.QQ=res.data.QQ;
        this.info.phone=res.data.phone;
        this.info.wechatImg=res.data.Wechat;
      })
    }
  }
</script>

<style scoped>
  .sidebar {
    position: fixed;
    top: 50%;
    right: 0;
    z-index: 100;
  }

  .sidebarItem {
    background-color: var(--red);
    display: flex;
    align-items: center;
    height: 1.6rem;
    cursor: pointer;
    margin-bottom: 3px;
    position: relative;
  }

  .sidebarItem2 {
    background-color: var(--red);
    display: flex;
    align-items: center;
    cursor: pointer;
    margin-bottom: 3px;
    position: relative;
  }

  .sidebarItemValue {
    display: flex;
    align-items: center;
    font-size: .4rem;
    color: #fff;
    padding: 0 0 0 .6rem;
    display: none;
    position: absolute;
    right: 1.6rem;
    width: 6rem;
    height: 100%;
    background-color: var(--red);
  }

  .sidebarItemIconCoat {
    width: 1.6rem;
    height: 1.6rem;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #b1b1b1;
  }

  .sidebarItemIcon {
    width: .7rem;
    height: auto;
  }

  .sidebarItem:hover .sidebarItemIconCoat {
    background-color: var(--red);
  }
  .sidebarItem:hover .sidebarItemValue {
    display: flex;
  }
  .sidebarItemValue2{
    display: none;
    position: absolute;
    right: 1.6rem;
    padding: .6rem;
    height: auto;
    background-color: #f7f7f7f7;
    display: none;
    align-items: center;
    justify-content: center;
    border-radius: 20px;

  }
  .sidebarItemValue2 p{
    font-size: .4rem;
    color: #999999;
  }
  .sidebarItemValue2 img{
    width:4rem;
    height:auto;
    margin-bottom: .3rem;
  }
  .sidebarItem2:hover .sidebarItemValue2{
    display: block;
  }
  .sidebarItem2:hover .sidebarItemIconCoat {
      background-color: var(--red);
    }
</style>